import { CreateControllerFn, IControllerConfig, IUser } from '@wix/yoshi-flow-editor';

import { getLocale, navigateToMyRewardsPage, promptLogin } from '../../utils';
import { createSettingsEventHandler, loadData, loadUserData } from './utils';
import { ControllerProps } from './Widget/types';

const createController: CreateControllerFn = async ({ controllerConfig, flowAPI }) => {
  const { setProps, wixCodeApi } = controllerConfig;
  const { isEditor } = flowAPI.environment;

  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };

  const { subscribeToSettingsChanges, handleSettingsConfigChange } = createSettingsEventHandler(
    flowAPI,
    setWidgetProps,
  );

  setProps({ fitToContentHeight: true });

  return {
    async pageReady() {
      subscribeToSettingsChanges();

      let isLoggedIn = wixCodeApi.user.currentUser.loggedIn;
      const { loyaltyProgram, pointsBalance, rewardsAvailable } = await loadData(flowAPI);

      wixCodeApi.user.onLogin(async (user: IUser) => {
        isLoggedIn = user.loggedIn;
        const userData = await loadUserData(flowAPI);
        setWidgetProps({ isLoggedIn, ...userData });
      });

      const handleNavigation = async (pageLink?: object) => {
        if (!isLoggedIn) {
          const hasLoggedIn = await promptLogin(flowAPI);
          if (!hasLoggedIn) {
            return;
          }
        }

        if (!pageLink) {
          navigateToMyRewardsPage(flowAPI);
          return;
        }

        if (wixCodeApi?.location?.navigateTo) {
          wixCodeApi.location.navigateTo(pageLink);
        }
      };

      setWidgetProps({
        onNavigate: handleNavigation,
        locale: getLocale(flowAPI),
        isLoggedIn: isEditor ? true : isLoggedIn,
        loyaltyProgram,
        pointsBalance,
        rewardsAvailable,
      });
    },
    updateConfig(_$w, newConfig) {
      handleSettingsConfigChange(newConfig as IControllerConfig);
    },
  };
};

export default createController;
